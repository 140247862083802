
@import "../global.scss";


.app{
     min-height: 100vh;
    //  min-width: 100vw;
     position: relative;
     color: $fontColor;
     overflow: hidden;


    //  margin-inline: auto;

    .fade-in-section {
        opacity: 0;
        transform: translateY(10vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1s ease-out;
        will-change: opacity, visibility;
      }
      .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
        
        
      }



    .sections{
        // width: 100%;
        // width:100vw
        // margin-inline: auto;
        overflow: hidden;
        // padding: 1rem 0;
        position: relative;
        // overflow-x: hidden;
        // overflow: hidden;
        
        margin-bottom: 10px;
        top: 30px;
        display: flex;
        flex-direction: column;
        // scrollbar-width: none; // for firefox
        &::-webkit-scrollbar{
            display: none;
        }

    // > *{
    //     overflow: hidden;
    //     // min-width: 100%;
    //     // -webkit-transform-style: preserve - 3 d; 
    //     // -webkit-backface-visibility: hidden;
      
    //     }
     
    }

 
    .sidebar-nav{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: fixed;
      right: 0;
      // padding-right: 5%;
      bottom: 10%;
      background-color: transparent;
     
      @include respond(mobile){
         position: unset;
         align-items: center;
         width: 100%;
         height: auto;
      }
    }
   

    


    // removes all transitions , transforms , animations for users who do not like motion-related content
    @media (prefers-reduced-motion: reduce) {
        *,
        ::before,
        ::after {
          animation-delay: -1ms !important;
          animation-duration: 1ms !important;
          animation-iteration-count: 1 !important;
          background-attachment: initial !important;
          scroll-behavior: auto !important;
          transition-duration: 0s !important;
          transition-delay: 0s !important;
        }
      }


      
}