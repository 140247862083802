@import "../global.scss";

.contact {
            width: 100%;
            height: auto;
            // margin-top: 100px;
            margin-bottom: 30px;
            color: $fontColor;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            justify-content: center;
            padding-bottom: 15px;
            

             
             i{
                color: $stylecolor;
                margin-right: 10px;
            }

            h1{
                display: flex;
                justify-content: center;
                font-size: 40px;
                
            }

            .contactme{
                
                margin-top: 5rem;
                margin-bottom: 4rem;
                margin-inline: auto;
                max-width: 700px;

                @include respond(mobile){
                    margin-left:18px;
                    margin-right: 18px;
                }
                // align-items: center;
                // margin-inline: auto

               p{
                text-align: center;
                line-height: 1.5;
                // align-items: center;
                // display: flex;
                // justify-content: center;
                font-size: 1.2rem;
               }
            }

            a{
                text-decoration: none;
            }

            .hello {
                        font-size: 1.3rem;
                        outline: 3px solid $stylecolor;
                        background: $stylecolor;
                        padding: 1em 2em;
                        margin-bottom: 10px;
                        transition: all ease-in-out 300ms;
                        text-decoration: none;
                        color: $fontColor;

                       
                        
                       
                        @media (hover: hover) and (pointer: fine){
                                &:hover{
                                        outline: 3px solid $stylecolor;
                                        outline-offset: 6px;
                                }
                        }
            }

}