@import "../global.scss";


.about{
    // background-color: cyan;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-bottom: 100px;
    margin-top: 50px;
    color: $fontColor;
  
    // min-height: 70%;
    // justify-content: center;

    
    i{
        color: $stylecolor;
    }

    h1{
        display: flex;
        justify-content: center;
        font-size: 40px;
        margin: 30px 0;
        @include respond(mobile){
            padding-bottom: 10px;
        }
       
        
    }

    .container {
             
                display: flex;
                flex-direction: row;
                position: relative;
                // top: 15%;
                padding-top: 10px;
                // justify-content: center;
                // align-items: center;
                // background-color: blue;
                width: 100%;
                height: auto;

                @include respond(mobile){
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: center;
                }

       

                .biography {
                            height: auto;
                            margin-block-start: 1em;
                            margin-block-end: 1em;
                            margin-inline-start: 20px;
                            margin-inline-end: 20px;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            flex-direction: column;
            // background-color: white;
                            // background-color: greenyellow;
                            border-left: 3px solid $stylecolor;
            // display: flex;
            // flex-direction: column;
                            // min-width: 400px;
                            max-width: 700px;

                            p{  
                                
                                font-size: 1.2rem;
                                margin-top: 15px;
                                margin-bottom: 10px;
                                margin-left: 20px;
                                line-height: 1.6;
                  
                            } 

                }

    

    .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 100px;
        padding-left: 20px;
        padding-right: 40px;
        // background-color: red;

        @include respond(mobile){
            padding-left: 40px;
            padding-top: 40px;
            padding-bottom: 74px;
            margin-left: 0px;
            
          
        }

        // background-color: red;

        .wrapper {

            max-width: 100%;
            width: 300px;
            height: 300px;
            // background-color: bisque;

            img{
                display: block;
                height: 100%;
            }

        }

    }



            


    }


}